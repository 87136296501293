import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

export default class Banner extends Component {
    render(){
        return(
            <div className="Banner" >
                <div className="text-center">
                    <div className="row" style={{alignItems:"center"}} >
                        <div className="col-md-7">
                        {   this.props.sensibleSkinScore > 10 ?
                            <div className="Banner-text">
                                <p>Conoce tu tipo de piel y su cuidado especializado</p>
                                <Button color="secondary" className="animate__animated animate__pulse animate__infinite Banner-button" onClick={e => this.props.changeSensibleScore(e)}>Ver más</Button>
                            </div>
                            :
                            <div className="Banner-text">
                                <p>Conoce tu tipo de piel y su cuidado especializado</p>
                                <Link 
                                className="btn btn-light animate__animated animate__pulse animate__infinite " to="/test" 
                                style={{
                                    margin:'0px', 
                                    width:'auto', 
                                    backgroundColor:"#004986",
                                    color:"white"}}>REHACER TEST</Link>
                            </div>
                        }
                        </div>
                        <div className="col-md-5 box lg">
                                <img src="/assets/BannerFooter.png" className="Banner-img" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}