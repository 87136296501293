export const data = [
    {
        configStyle: ['' ,'#004984'],
        back:"#EFF2F9",
        background:'/assets/fondos/Fondo1.png',
        configIcon: ['/assets/faces/1.png','/assets/faces/2.png','/assets/faces/3.png','/assets/faces/4.png'],
        question: 'Generalmente tu rostro:',
        answers: ['Carece de hidratación y frecuentemente siento mi piel tirante.',
        'Presenta brillo en mejillas y zona T.',
        'La zona T es brillante pero las mejillas no, de hecho a veces se sienten resecas.',
        'Es suave y terso.'],
        skinType: [1,2,3,4],
        sensibleSkin: [0,0,0,0]
    },
    {
        configStyle: ['' ,'#004984'],
        back:"#EFF2F9",
        background:'/assets/fondos/Fondo2.png',
        configIcon: ['/assets/faces/3.png','/assets/faces/5.png','/assets/faces/4.png','/assets/faces/6.png'],
        question: '¿Presentas granos y espinillas en la piel?',
        answers: ['Sí, siempre he tenido granos y espinillas pero más en la zona T (frente, nariz, mentón).',
        'He tenido granos y espinillas en todo el rostro.',
        'Nunca he sufrido de acné en mi rostro.',
        'Sí pero no tan frecuente.'],
        skinType: [3,2,1,4],
        sensibleSkin: [0,0,0,0]
    },    
    {
        configStyle: ['' ,'#004984'],
        back:"#EFF2F9",
        background:'/assets/fondos/Fondo3.png',
        configIcon: ['/assets/faces/6.png','/assets/faces/2.png','/assets/faces/3.png','/assets/faces/4.png'],
        question: 'Cuando me miro al espejo en la mañana mi piel luce:',
        answers: ['Definitivamente luce apagada.',
        'Mis mejillas y frente brillan.',
        'Mi nariz y frente brillan mientras que mis mejillas lucen normal.',
        'Se ve igual que antes de acostarme.'],
        skinType: [1,2,3,4],
        sensibleSkin: [0,0,0,0]
    },
    {
        configStyle: ['' ,'#004984'],
        back:"#EFF2F9",
        background:'/assets/fondos/Fondo4.png',
        configIcon: ['/assets/faces/4.png','/assets/faces/5.png','/assets/faces/8.png','/assets/faces/9.png'],
        question: '¿Cómo describirías los poros en tu rostro?',
        answers: ['Apenas y se notan.',
        'Son muy visibles y dilatados en todo mi rostro.',
        'Son más visibles en la zona T.',
        'Son pequeños y pasan desapercibidos.'],
        skinType: [1,2,3,4],
        sensibleSkin: [0,0,0,0]
    },
    {
        configStyle: ['' ,'#004984'],
        back:"#EFF2F9",
        background:'/assets/fondos/Fondo5.png',
        configIcon: ['/assets/faces/6.png','/assets/faces/5.png','/assets/faces/10.png','/assets/faces/9.png'],
        question: "Si tocas tu rostro ",
        question2:"(mejillas, nariz y frente),",
        question3:"¿cómo lo sientes?",
        answers: ['Seco.',
        'Grasoso.',
        'En unas zonas grasoso, en otras normal o inclusive seco.',
        'Suave.'],
        skinType: [1,2,3,4],
        sensibleSkin: [0,0,0,0]
    },
    {
        configStyle: ['' ,'#004984'],
        back:"#EFF2F9",
        background:'/assets/fondos/Fondo6.png',
        configIcon: ['/assets/faces/4.png','/assets/faces/9.png','/assets/faces/16.png','/assets/faces/6.png'],
        question: '¿Es normal que sientas picor en tu piel?',
        answers: ['Mi piel es áspera pero no llega al punto de picarme.',
        'Nunca he sentido picor en mi piel.',
        'Es tan frecuente que me desespero a veces.',
        'Generalmente me ocurre en situaciones de estrés.'],
        skinType: [1,1,0,0],
        sensibleSkin: [0,0,0,0]
    },
    {
        configStyle: ['' ,'#004984'],
        back:"#EFF2F9",
        background:'/assets/fondos/Fondo7.png',
        configIcon: ['/assets/faces/4.png','/assets/faces/9.png','/assets/faces/12.png','/assets/faces/11.png'],
        question: '¿Tu piel ha presentado descamación?',
        answers: ['En pocas ocasiones me ha pasado.',
        'Nunca me ha pasado.',
        'Es muy frecuente.',
        'Aunque no es tan frecuente, sí me ha pasado varias veces.'],
        skinType: [1,1,0,0],
        sensibleSkin: [0,0,0,0]
    },
    {
        configStyle: ['' ,'#004984'],
        back:"#EFF2F9",
        background:'/assets/fondos/Fondo8.png',
        configIcon: ['/assets/faces/4.png','/assets/faces/7.png','/assets/faces/9.png','/assets/faces/6.png'],
        question: '¿Consideras que tu piel es tirante y luce opaca?',
        answers: ['En rostro.',
        'En una zona puntual (Ejemplo: manos, brazos, rostro).',
        'No tengo ninguna zona con piel tirante.',
        'Creo que todo mi cuerpo es de piel tirante.'],
        skinType: [1,1,0,1],
        sensibleSkin: [0,0,0,0]
    },
    {
        configStyle: ['' ,'#004984'],
        back:"#EFF2F9",
        background:'/assets/fondos/Fondo9.png',
        configIcon: ['/assets/faces/4.png','/assets/faces/9.png','/assets/faces/16.png','/assets/faces/4.png'],
        question: '¿Has sufrido alguna vez de irritación en la piel?',
        answers: ['Sí, es muy frecuente.',
        'No es tan frecuente pero en varias ocasiones se me ha irritado la piel.',
        'Nunca.',
        'Han sido muy pocas veces.'],
        skinType: [0,0,0,0],
        sensibleSkin: [1,1,0,0]
    },
    {
        configStyle: ['' ,'#004984'],
        back:"#EFF2F9",
        background:'/assets/fondos/Fondo10.png',
        configIcon: ['/assets/faces/16.png','/assets/faces/6.png','/assets/faces/9.png','/assets/faces/4.png'],
        question: '¿Sufres de alergias y enrojecimiento en la piel?',
        answers: ['Mi piel se enrojece con cualquier cosa.',
        'Sí me ocurre pero no es tan frecuente.',
        'Por fortuna no.',
        'Ha sucedido tan poco que no lo considero relevante.'],
        skinType: [0,0,0,0],
        sensibleSkin: [4,1,0,0]
    },
    {
        configStyle: ['' ,'#004984'],
        back:"#EFF2F9",
        background:'/assets/fondos/Fondo11.png',
        configIcon: ['/assets/faces/16.png','/assets/faces/12.png','/assets/faces/4.png','/assets/faces/9.png'],
        question: 'Frente a los cambios de temperatura mi piel:',
        answers: ['Se enrojece con facilidad.',
        'Cambia de aspecto, se vuelve más grasosa o, por el contrario, la siento reseca.',
        'Mi piel luce igual.',
        'No he notado ningún cambio.'],
        skinType: [0,0,0,0],
        sensibleSkin: [1,1,0,0]
    },
    {
        configStyle: ['' ,'#004984'],
        back:"#EFF2F9",
        background:'/assets/fondos/Fondo12.png',
        configIcon: ['/assets/faces/16.png','/assets/faces/17.png','/assets/faces/4.png','/assets/faces/9.png'],
        question: '¿Sientes picazón en tu piel?',
        answers: ['Es muy habitual.',
        'Me pasa mucho.',
        'En realidad no.',
        'No lo he sentido.'],
        skinType: [0,0,0,0],
        sensibleSkin: [1,1,0,0]
    }
];
