export const result = [
    {
        configStyle: ['' ,'#004986'],
        skinIcon: 'PielSensible.png',
        inicio:'Después de tus respuestas, podemos decir que tu piel es: ',
        skinResult: 'Sensible',
        color: [
            {//clean
                colorFondo:"#FFFFFF",
                colorLetra:"#144780",
                colorDesc:"#DFEEF8"
            },
            {//hydration
                colorFondo:"#FFFFFF",
                colorLetra:"#144780",
                colorDesc:"#DFEEF8"
            },
            {//solarprotc
                colorFondo:"#FFFFFF",
                colorLetra:"#144780",
                colorDesc:"#DFEEF8"
            }
        ],
        pdf : '/assets/Sensible.pdf',
        description: 'tu piel se irrita con facilidad y está expuesta a agentes sensibilizantes a las que puede mostrar signos de alergia. Necesitas una humectación eficaz que ayude a preservar la barrera de tu piel y disminuir molestias mayores.' ,
        clean: [
            {
                description: 'Cetaphil® Toallitas de Limpieza Facial',
                image: '/assets/products/Producto1.png',
                type: 'Rostro'
            },
            {
                description: 'Cetaphil® Barra de Limpieza Suave',
                image: '/assets/products/Producto4.png',
                type: 'Cuerpo'
            },
            {
                description: 'Cetaphil® Loción Limpiadora 473mL',
                image: '/assets/products/Producto5.png',
                type: 'Rostro-Cuerpo'
            },
        ],
        hydration: [
            {
                description: 'Cetaphil® Loción Facial Hidratante de día',
                image: '/assets/products/Producto6.png',
                type: 'Rostro'
            },
            {
                description: 'Cetaphil® Loción Facial hidratante de noche',
                image: '/assets/products/Producto7.png',
                type: 'Rostro'
            },
            {
                description: 'Cetaphil® Loción Ultrahumectante 473mL',
                image: '/assets/products/Producto8.png',
                type: 'Cuerpo'
            },
            
        ],
        solarProtection: [
            {
                description: 'Cetaphil® Gel Ligero 100mL',
                image: '/assets/products/Producto18.png',
                type: 'Rostro - Cuerpo'
            }
        ]
    },
    {
        configStyle: ['' ,'#004986'],
        skinIcon: 'PielMuySeca.png',
        inicio:'Después de tus respuestas, podemos decir que tu piel es: ',
        skinResult: 'Muy seca',
        color: [
            {//clean
                colorFondo:"#FFFFFF",
                colorLetra:"#144780",
                colorDesc:"#DFEEF8"
            },
            {//hydration
                colorFondo:"#FFFFFF",
                colorLetra:"#144780",
                colorDesc:"#DFEEF8"
            },
            {//solarprotc
                colorFondo:"#FFFFFF",
                colorLetra:"#144780",
                colorDesc:"#DFEEF8"
            }
        ],
        pdf : '/assets/MuySeca.pdf',
        description: 'tu piel es muy propensa al enrojecimiento, tirantez y picor. Además, puede llegar a agrietarse produciendo descamación. Como su barrera cutánea no funciona bien, es más fácil la pérdida de agua. Debes hidratar tu piel día y noche para calmar la sensación de tirantez.' ,
        clean: [
            {
                description: 'Cetaphil® Toallitas de Limpieza Facial',
                image: '/assets/products/Producto1.png',
                type: 'Rostro'
            },
            {
                description: 'Cetaphil® Loción Limpiadora 473mL',
                image: '/assets/products/Producto5.png',
                type: 'Rostro'
            },
            {
                description: 'Cetaphil® PRO AD Control Limpiador',
                image: '/assets/products/Producto9.png',
                type: 'Cuerpo'
            },
        ],
        hydration: [
            {
                description: 'Cetaphil® Hidratante Facial diario FPS 50',
                image: '/assets/products/Producto10.png',
                type: 'Rostro'
            },
            {
                description: 'Cetaphil® PRO AD Control Hidratante',
                image: '/assets/products/Producto11.png',
                type: 'Cuerpo'
            },
        ],
        solarProtection: [
            {
                description: 'Cetaphil® Gel Ligero 100mL',
                before:"111,300",
                after:"100,170",
                image: '/assets/products/Producto18.png',
                type: 'Rostro-Cuerpo'
            }
            
        ]
        
    },
    {
        configStyle: ['' ,'#004986'],
        skinIcon: 'PielSeca.png',
        inicio:'Después de tus respuestas, podemos decir que tu piel es: ',
        skinResult: 'Seca',
        color: [
            {//clean
                colorFondo:"#FFFFFF",
                colorLetra:"#144780",
                colorDesc:"#DFEEF8"
            },
            {//hydration
                colorFondo:"#FFFFFF",
                colorLetra:"#144780",
                colorDesc:"#DFEEF8"
            },
            {//solarprotc
                colorFondo:"#FFFFFF",
                colorLetra:"#144780",
                colorDesc:"#DFEEF8"
            }
        ],
        pdf : '/assets/Seca.pdf',
        description: 'tu piel no produce los niveles de grasa normales y por eso la puedes sentir áspera y con tirantez. Si está excesivamente deshidratada puede volverse frágil. Necesitas una rutina de cuidado que se fundamente en una buena hidratación.' ,
        clean: [
            {
                description: 'Cetaphil® Toallitas de Limpieza Facial',
                image: '/assets/products/Producto1.png',
                type: 'Rostro'
            },
            {
                description: 'Cetaphil® Barra Limpieza Profunda',
                image: '/assets/products/Producto4.png',
                type: 'Cuerpo'
            },
            {
                description: 'Cetaphil® Loción Limpiadora 473mL',
                image: '/assets/products/Producto5.png',
                type: 'Rostro-Cuerpo'
            },
        ],
        hydration: [
            {
                description: 'Cetaphil® Loción Facial Hidratante de día',
                image: '/assets/products/Producto6.png',
                type: 'Rostro'
            },
            {
                description: 'Cetaphil® Loción Facial Hidratante de noche',
                image: '/assets/products/Producto7.png',
                type: 'Rostro'
            },
            {
                description: 'Cetaphil® Crema Humectante',
                image: '/assets/products/Producto15.png',
                type: 'Cuerpo'
            },
        ],
        solarProtection: [
            {
                description: 'Cetaphil® Gel Ligero 100mL',
                image: '/assets/products/Producto18.png',
                type: 'Rostro-Cuerpo'
            }
            
        ]
    },
    {
        configStyle: ['' ,'#004986'],
        skinIcon: 'PielGrasa.png',
        inicio:'Después de tus respuestas, podemos decir que tu piel es: ',
        skinResult: 'Grasa',
        color: [
            {//clean
                colorFondo:"#FFFFFF",
                colorLetra:"#144780",
                colorDesc:"#DFEEF8"
            },
            {//hydration
                colorFondo:"#FFFFFF",
                colorLetra:"#144780",
                colorDesc:"#DFEEF8"
            },
            {//solarprotc
                colorFondo:"#FFFFFF",
                colorLetra:"#144780",
                colorDesc:"#DFEEF8"
            }
        ],
        pdf : '/assets/Grasa.pdf',
        description: 'tu piel es un poco más gruesa y presenta brillo tanto en tu zona T como en las mejillas. Hay factores como la edad, la genética, las hormonas, la alimentación, el estrés o incluso algunas sustancias que se aplican en la piel, y pueden ocasionar piel grasa y con barros o espinillas. La limpieza y exfoliación en tu rutina de cuidado es fundamental.' ,
        clean: [
            {
                description: 'Cetaphil® Toallitas de Limpieza Facial',
                image: '/assets/products/Producto1.png',
                type: 'Rostro'
            },
            {
                description: 'Cetaphil® PRO AC Control Espuma',
                image: '/assets/products/Producto12.png',
                type: 'Rostro'
            },
            {
                description: 'Cetaphil® Barra Limpieza Profunda',
                image: '/assets/products/Producto4.png',
                type: 'Cuerpo'
            },
            
        ],
        hydration: [
            {
                description: 'Cetaphil® PRO AC Hidratante',
                image: '/assets/products/Producto13.png',
                type: 'Rostro'
            },
            {
                description: 'Cetaphil® Emulsión Hidratante',
                image: '/assets/products/Producto16.png',
                type: 'Cuerpo'
            },
        ],
        solarProtection: [
            {
                description: 'Cetaphil® Oil Control con y sin color',
                image: '/assets/products/Producto14.png',
                type: 'Rostro'
            }
            
        ]
    },
    {
        configStyle: ['' ,'#004986'],
        skinIcon: 'PielMixta.png',
        inicio:'Después de tus respuestas, podemos decir que tu piel es: ',
        skinResult: 'Mixta',
        color: [
            {//clean
                colorFondo:"#FFFFFF",
                colorLetra:"#144780",
                colorDesc:"#DFEEF8"
            },
            {//hydration
                colorFondo:"#FFFFFF",
                colorLetra:"#144780",
                colorDesc:"#DFEEF8"
            },
            {//solarprotc
                colorFondo:"#FFFFFF",
                colorLetra:"#144780",
                colorDesc:"#DFEEF8"
            }
        ],
        pdf : '/assets/Mixta.pdf',
        description: 'como su nombre lo indica, tu piel presenta 2 patologías. En tu zona T (frente, nariz, mentón) produce más grasa. Sin embargo, en la parte de las mejillas puedes tener la piel normal o incluso seca y presentar irritación.' ,
        clean: [
            {
                description: 'Cetaphil® Toallitas de Limpieza Facial',
                image: '/assets/products/Producto1.png',
                type: 'Rostro'
            },
            {
                description: 'Cetaphil® PRO AC Control Espuma',
                image: '/assets/products/Producto12.png',
                type: 'Rostro'
            },
            {
                description: 'Cetaphil® Barra Limpieza Profunda',
                image: '/assets/products/Producto4.png',
                type: 'Cuerpo'
            },
            
        ],
        hydration: [
            {
                description: 'Cetaphil® Loción Facial Hidratante de día',
                image: '/assets/products/Producto6.png',
                type: 'Rostro'
            },
            {
                description: 'Cetaphil® Loción Facial Hidratante de noche',
                image: '/assets/products/Producto7.png',
                type: 'Rostro'
            },
            {
                description: 'Cetaphil® Crema humectante',
                image: '/assets/products/Producto15.png',
                type: 'Cuerpo'
            },
        ],
        solarProtection: [
            {
                description: 'Cetaphil® Gel Ligero',
                image: '/assets/products/Producto18.png',
                type: 'Rostro-Cuerpo'
            },
            
        ]
    },
    {
        configStyle: ['' ,'#004986'],
        skinIcon: 'PielNormal.png', 
        pdf : '/assets/Normal.pdf', 
        inicio:'Después de tus respuestas, podemos decir que tu piel es: ',
        skinResult: 'Normal',
        color: [
            {//clean
                colorFondo:"#FFFFFF",
                colorLetra:"#144780",
                colorDesc:"#DFEEF8"
            },
            {//hydration
                colorFondo:"#FFFFFF",
                colorLetra:"#144780",
                colorDesc:"#DFEEF8"
            },
            {//solarprotc
                colorFondo:"#FFFFFF",
                colorLetra:"#144780",
                colorDesc:"#DFEEF8"
            }
        ],
        description: ' tu piel es suave, no se irrita con facilidad ni produce sebo en exceso. Tiene buena circulación sanguínea, no presenta aspereza sino por el contrario se siente suave y lisa.' ,
        clean: [
            {
                description: 'Cetaphil® Toallitas de Limpieza Facial',
                image: '/assets/products/Producto1.png',
                type: 'Rostro'
            },
            {
                description: 'Cetaphil® Barra Limpieza Profunda',
                image: '/assets/products/Producto4.png',
                type: 'Cuerpo'
            },
        ],
        hydration: [
            {
                description: 'Cetaphil® Loción Facial Hidratante de día',
                image: '/assets/products/Producto6.png',
                type: 'Rostro'
            },
            {
                description: 'Cetaphil® Loción Facial Hidratante de noche',
                image: '/assets/products/Producto7.png',
                type: 'Rostro'
            },
            {
                description: 'Cetaphil® Crema Humectante',
                image: '/assets/products/Producto15.png',
                type: 'Cuerpo'
            },
        ],
        solarProtection: [
            {
                description: 'Cetaphil® Oil Control con y sin color',
                image: '/assets/products/Producto14.png',
                type: 'Rostro'
            },
            
        ]
    }
]