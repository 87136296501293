import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from "./Footer";
import img from '../img/Recurso1.png';


export default class Main extends Component {
  render() {
    return (
      <React.Fragment>
        <Header/>
          <div className="row fondo" style={{margin:'auto'}}>
            <div className="col-md-5 contenido" style={{margin:'auto',padding:'auto'}}>
              <p style={{color:"#004788"}}>¿Cuál es mi <b> tipo de piel </b> y qué producto debo utilizar?</p>
              <Link className="btn animate__animated animate__pulse animate__infinite" to="/test" style={{ maxWidth:"250px",color:"white", backgroundColor:"#004788"}}><b>¡EMPIEZA EL TEST <span>AHORA!</span></b></Link>
            </div>
            <div className="col-md-6 hostImg">
              <img className="Image" src={img} alt="Imagen" ></img>
            </div>
          </div>
        <Footer/>
      </React.Fragment>
    );
  }
};