import React, {Component} from 'react';
import PielGrasa from '../../img/PielGrasa.svg';
import PielMixta from '../../img/PielMixta.svg';
import PielMuySeca from '../../img/PielMuySeca.svg';
import PielNormal from '../../img/PielNormal.svg';
import PielSeca from '../../img/PielSeca.svg';
import PielSensible from "../../img/PielSensible.svg";
import Redes from '../Redes';

export default class Header extends Component{

    ImageArray = [ 
        PielSensible,
        PielMuySeca,
        PielSeca,
        PielGrasa,
        PielMixta,
        PielNormal
    ];

    skinType(score){
        if(score <= 8){
            return 1
        } else if (score <= 12) {
            return 2
        } else if (score <= 16) {
            return 3
        } else if (score <= 24) {
            return 4
        } else {
            return 5
        }
    }

    render() {
        
        const { 
            skinScore,
            sensibleSkinScore 
        } = this.props;

        const idx = sensibleSkinScore > 1 ? 0 : this.skinType(skinScore);
        const data = this.props.data;
        
        return (
            <div style={{marginBottom:"0px"}}>
                <div className="row justify-content-center resultFondo" style={{backgroundColor:data[idx].configStyle[0]}}>
                    <div className="col-md-3 headerImg text-center">
                        <img className="resultImg imageHeader" src={this.ImageArray[idx]} alt="Imagen"></img>
                    </div>
                    <div className="col-md-5 offset-1 resultContenido text-center" style={{color:data[idx].configStyle[1]}}>
                        <div style={{display:"flex",justifyContent:"flex-end"}}>
                        <Redes/>
                        </div>
                        <p className="resultText" style={{
                            }}>
                            {data[idx].inicio}
                        </p>
                        <h1 className="resultTitle" style={{
                            fontSize: 'calc(4rem + 0.1vw)',
                            }}>
                            {data[idx].skinResult}
                        </h1>
                        <hr style={{
                            marginBottom:"4%"
                            }}/>
                        <p className="resultText" style={{
                            paddingRight:"4.5vw"
                            }}> 
                            <strong>Piel {data[idx].skinResult}: </strong>{data[idx].description}
                        </p>
                    </div>
                </div>
            </div>
        )
    }
};