import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SimpleSlider from './Slider';
import NoSlider from './noSlider';


export default class Main extends Component {

    skinType(score){
        if(score <= 9){
            return 1
        } else if (score <= 12) {
            return 2
        } else if (score <= 16) {
            return 3
        } else if (score <= 24) {
            return 4
        } else {
            return 5
        }
    }
    constructor(props){
        super(props);
        this.state={
            stage: 'clean'
        };
    }

    render() {
        const { 
            skinScore,
            sensibleSkinScore
        } = this.props;
        const idx = sensibleSkinScore > 1 ? 0 : this.skinType(skinScore);
        return(
        <React.Fragment>
            <div className="" style={{backgroundColor:"#EFF2F9",padding:"5% 0%"}}>
                <div className="row justify-content-center mainText">
                    <div className="col-md-3">
                        <h2 style={{color:'#004788',textAlign:'center',fontFamily:"Poppins-SemiBold",marginTop:"10px"}}>
                            <b>
                                Los productos que te recomendamos para tu rutina de cuidado diario son:
                            </b>
                        </h2> 
                    </div>
                </div>
                <div className="row col-md-6 col-7 justify-content-center " style={{width:"100vw",margin:"auto", marginBottom:"5vh", marginTop:"5vh"}}>
                    <button outline
                        className='statesBTN'
                        type="button"
                        active={this.state.stage === 'clean'}
                        style={{margin:"auto",marginBottom:"10px",}}
                        onClick={ e => {
                            this.setState({ stage: "clean"})}}> 
                        Limpieza
                    </button>
                    <button 
                        className='statesBTN'
                        active={this.state.stage === 'hydration'}
                        style={{margin:"auto",marginBottom:"10px",}}
                        onClick={ e => {
                            this.setState({ stage: "hydration"})}}> 
                        Hidratación 
                    </button>
                    <button outline
                        className='statesBTN'
                        active={this.state.stage === 'solarProtection'}
                        style={{margin:"auto",marginBottom:"10px" , }}
                        onClick={ e => {
                            this.setState({ stage: "solarProtection"})}}> 
                        Protección Solar 
                    </button>
                </div>
                <div className="justify-content-center desktopSlider">
                    <SimpleSlider data={this.props.data} stage={this.state.stage} id={idx}/>
                </div>
                <div className="justify-content-center mobileSlider">
                    <NoSlider data={this.props.data} stage={this.state.stage} id={idx}/>
                </div>
                <div className="row justify-content-center downloadBTN">
                    <Link 
                        className="btn btn-light animate__animated animate__pulse animate__infinite"
                        target="_blank"
                        to={{pathname: this.props.data[idx].pdf}}
                        download={this.props.data[idx].skinResult} 
                        style={{margin:'45px', width:'auto', height:'auto',  backgroundColor:'#004986', color:'#FFFFFF'}}>
                        DESCARGA AQUÍ TU RESULTADO
                    </Link>                    
                </div>
            </div>
        </React.Fragment>
        );
    }
}


/* 
<Button outline
                        className='statesBTN'
                        aria-pressed="true"
                        active={this.state.stage === 'clean'}
                        style={{margin:"auto",marginBottom:"10px", width:"200px",fontSize:"1.2rem"}}
                        onClick={ e => {
                            this.setState({ stage: "clean"})}}> 
                        Limpieza
                    </Button>

<div className="row justify-content-center">
    <Link 
        className="btn btn-light animate__animated animate__pulse animate__infinite"
        target="_blank" 
        to={{pathname: this.props.data[idx].pdf}} 
        style={{margin:'45px', width:'250px', height:'73px', lineHeight:3.5, backgroundColor:'#004986', color:'#FFFFFF',fontFamily:"Poppins-SemiBold"}}>
        Descarga aquí tu resultado
    </Link>                    
</div> */