import React, {Component} from 'react';
import footer from '../img/footer.png';
import Redes from '../components/Redes';

export default class Footer extends Component{
    render() {
        return (
            <footer>
                <Redes></Redes>
                <a
                className=''
                target="blank"
                href='https://www.cetaphil.com.co/'
                >
                    <img className="footer" src={footer} alt="Imagen" height='70%'></img>
                </a>
            </footer>
        )
    }
};