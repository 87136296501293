import React, {Component} from 'react';

export default class Redes extends Component{
   render() {
      return (
         <>
               <a 
                  className="redesPic2" 
                  href="https://www.facebook.com/cetaphilcolombia"
                  target="blank"
               >
                  <img className='' src="/assets/redes/Facebook.png" alt="" />
               </a>
               <a 
                  className="redesPic" 
                  href="https://instagram.com/cetaphilcol?igshid=YmMyMTA2M2Y="
                  target="blank"
               >
                  <img  src="/assets/redes/Instagram.png" alt="" />
               </a>
               <a 
                  className="redesPic2" 
                  target="blank"
                  href="https://www.youtube.com/user/GaldermaColombia" 
               >
                  <img className='' src="/assets/redes/Youtube.png" alt="" />
               </a>
         </>
      )
   }
};